import * as React from "react";
import { navigate } from "gatsby";
import { SEO } from "@components/page-layout";
import { SignIn } from "@containers/sign-in";
import { Box } from "@components/box";
import { isLoggedIn } from "@helpers/auth";

const LoginPage = () => {
  if (isLoggedIn()) {
    navigate("/dashboard");
  }

  return (
    <>
      <SEO title="Login" bodyClass="signup" />
      <Box className="flex justify-center items-center px-[22px] sm:px-[11px] xs:p-0">
        <SignIn />
      </Box>
    </>
  );
};

export default LoginPage;
