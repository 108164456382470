import * as React from "react";
import { navigate } from "gatsby";
import { Box } from "@components/box";
import {
  ValidationMessage,
  Button,
  PasswordInput,
  TextInput,
} from "@brainfinance/icash-component-library";
import { Subheading } from "@components/typography";
import { GatsbyLink } from "@components/gatsby-link";
import { SignUpContainer, SignUpLeft, SignUpRight } from "@components/sign-up";

import { postSignIn, setLocalStorageUserSession } from "@helpers/auth";
import { logBranchEvent } from "@helpers/branch";

// Images
import instantLoans from "@images/products/ic-instant-loans-xl.svg";
import interac from "@images/interac.svg";
import iCashSymbolBlue from "@images/icash-symbol-blue.svg";

export const SignIn = () => {
  const [email, setEmail] = React.useState<string>();
  const [password, setPassword] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | undefined>();

  const handleChangeEmail = (email?: string | number) => {
    if (email) setEmail(email.toString());
    else setEmail(undefined);
  };

  const handleSubmitLogin = () => {
    if (email && password) {
      setLoading(true);
      postSignIn(email, password)
        .then((res) => res.json())
        .then((data) => {
          if (data.error) throw new Error(data.error.message);
          if (!data.result?.session?.token)
            throw new Error(
              "An error occurred. Please contact the support team."
            );
          logBranchEvent("auth_login_submitted", { userEmail: email });
          setLocalStorageUserSession(data.result.session.token);
          navigate("/dashboard");
        })
        .catch((e) => {
          setError(e.message);
          setLoading(false);
        });
    }
  };

  return (
    <SignUpContainer>
      {loading ? (
        <div className="flex flex-1 w-full h-full justify-center">
          <img src={iCashSymbolBlue} width={68} height={44} />
        </div>
      ) : (
        <SignUpLeft className="max-w-[475px] md:max-w-none md:w-1/2">
          <Box display="flex" className="flex-col">
            <Box className={error ? "mb-[33px]" : "mb-[3.25rem] md:mb-[33px]"}>
              <Subheading>Sign in</Subheading>
            </Box>
            {error ? (
              <ValidationMessage status="error">{error}</ValidationMessage>
            ) : null}
            <Box>
              <Box display="flex" className="flex-col space-y-1.5 mb-[2rem]">
                <TextInput label="Email" onChange={handleChangeEmail} />
                <PasswordInput
                  id="password"
                  label="Password"
                  onChange={setPassword}
                />
              </Box>
              <Box display="flex" className="justify-end">
                <Button
                  disabled={!email || !password}
                  appearance="primary"
                  onClick={handleSubmitLogin}
                >
                  Continue
                </Button>
              </Box>
              <p className="global--small-label leading-[26px] mt-[22px]">
                Forgot your password?{" "}
                <GatsbyLink to="/forgot-password">Help me sign in</GatsbyLink>
                <br />
                Do not have an account yet?{" "}
                <GatsbyLink to="/signup">Create an account</GatsbyLink>
              </p>
            </Box>
          </Box>
        </SignUpLeft>
      )}
      <SignUpRight className="w-[845px] lg:w-[590px] md:w-1/2">
        <>
          <img
            src={instantLoans}
            className="absolute h-[527px] right-0 t-[46px] z-0 lg:h-[368px] md:h-[260px]"
            alt="Product image"
          />
          <Box className="max-w-[330px] mt-[34%] z-[1] lg:max-w-[240px] lg:mt-[50%] md:mt-[70%]">
            <Subheading className="mb-[44px] md:text-[26px] md:leading-[33px]">
              Instant loans, 24 hours a day
            </Subheading>
            <img src={interac} alt="Interac logo" className="w-[60px]" />
            <p className="global--small !text-[12px] text-interface-300 my-[22px]">
              With iCash instant loans, you can borrow up to $1500, 24/7.
              Receive your funds via e-Transfer almost instantaneously after
              signing your contract.
            </p>
          </Box>
        </>
      </SignUpRight>
    </SignUpContainer>
  );
};
